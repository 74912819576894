export default [
  {
    path: '/auth/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
    },
  },
  {
    path: '/auth/reset-password/:token',
    name: 'auth-reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
    props: true,
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/register',
    name: 'auth-register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/registered',
    name: 'auth-registered',
    component: () => import('@/views/auth/Registered.vue'),
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/verify/:token',
    name: 'auth-verify',
    component: () => import('@/views/auth/Verify.vue'),
    props: true,
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/invite/:token',
    name: 'auth-verify',
    component: () => import('@/views/auth/Invite.vue'),
    props: true,
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/auth/not-authorized',
    name: 'auth-not-authorized',
    component: () => import('@/views/auth/NotAuthorized.vue'),
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
    },
  },
]
