export default [
  {
    path: '/organisations',
    name: 'organisations',
    component: () => import('@/views/Organisations.vue'),
    meta: {
      resource: 'Organisation',
      action: 'Update',
      pageTitle: 'Organisations',
      breadcrumb: [
        {
          text: 'All Enterprises',
          active: true,
          to: { name: 'enterprises' },
        },
      ],
    },
  },
  {
    path: '/organisation',
    name: 'organisation-view',
    props: true,
    component: () => import('@/views/organisation/viewOrganisation/ViewOrganisation.vue'),
    meta: {
      resource: 'Organisation',
      action: 'Read',
      pageTitle: 'Organisation',
      breadcrumb: [
        {
          text: 'Your Organisation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organisation/:uid',
    name: 'admin-organisation-view',
    props: true,
    component: () => import('@/views/organisation/viewOrganisation/AdminViewOrganisation.vue'),
    meta: {
      resource: 'Organisation',
      action: 'Update',
      pageTitle: 'Organisation',
      breadcrumb: [
        {
          text: 'Your Organisation',
          active: true,
        },
      ],
    },
  },
]
