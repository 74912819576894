import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'

import organisations from '@/router/routes/organisation'
import users from '@/router/routes/users';
import networks from '@/router/routes/networks';
import issues from '@/router/routes/issues';
import targets from '@/router/routes/targets';
import dashboard from './routes/dashboard'
import notificationGroups from './routes/notificationGroups'
import auth from './routes/auth'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...dashboard,
    ...auth,
    ...users,
    ...networks,
    ...targets,
    ...issues,
    ...notificationGroups,
    {
      path: '/roles',
      name: 'roles',
      component: () => import('@/views/roles/Roles.vue'),
      meta: {
        resource: 'Role',
        action: 'Read',
        pageTitle: 'Roles',
      },
    },
    {
      path: '/activities',
      name: 'activities',
      component: () => import('@/views/activities/Activities.vue'),
      meta: {
        resource: 'Activity',
        action: 'Read',
        pageTitle: 'Activities',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/profile/Profile.vue'),
      meta: {
        resource: 'Role',
        action: 'Read',
        pageTitle: 'Profile',
      },
    },
    ...organisations,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'Read',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
      resource: 'Auth',
      action: 'Read',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = await isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'auth-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
