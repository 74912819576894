import Vue from 'vue'
// import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import BootsrapVue from 'bootstrap-vue';
import moment from 'moment';
import TerminologyService from '@/services/TerminologyService';

import {
  ValidationObserver,
  ValidationProvider,
  extend,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import VueGapi from 'vue-gapi';
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'

// Install VeeValidate components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

// VueValidate custom validations
extend('lettersOrSpecial', {
  validate(value) {
    const pattern = /[a-zA-Z.!#$%&’*+/=?^_`{|}~-]/i
    return pattern.test(value);
  },
  message: 'This field must contain at least one letter or special character',
});

extend('match', {
  validate(value, { match }) {
    return value === match;
  },
  message: 'This field must match the above',
  params: ['match'],
});

// extend('mixedOrNumericLimit', {
//   validate(value, { max }) {
//
//     return !(value.length > max && Number.isInteger(value));
//   },
//   message: 'This field must be not be less than { max } characters if a number',
//   params: ['max'],
// });

// BSV Plugin Registration
Vue.use(BootsrapVue)

// Composition API
Vue.use(VueCompositionAPI)

// Google api
Vue.use(VueGapi, {});

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.filter('formatDate', value => {
  if (!value) return '';
  return moment(String(value)).format('DD/MM/YYYY');
});

Vue.filter('formatDateTime', value => {
  if (!value) return '';
  return moment(String(value)).format('DD/MM/YYYY HH:mm');
});

Vue.filter('capEachWord', value => {
  if (!value) return '';
  return value
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
});

Vue.filter('boolToYes', value => (value ? 'Yes' : 'No'));

// eslint-disable-next-line consistent-return
Vue.filter('term', (word, capitalize = false) => {
  const establishment = 'prison'; // todo link up to establishment

  if (!word) return '';

  if (word === 'prison') {
    return TerminologyService.getEstablishmentTextForPrison(establishment, capitalize);
  } if (word === 'prisoner') {
    return TerminologyService.getEstablishmentTextForPrisoner(establishment, capitalize);
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
