export default [
  {
    path: '/networks',
    name: 'networks',
    component: () => import('@/views/networks/Networks.vue'),
    meta: {
      resource: 'Network',
      action: 'Read',
      pageTitle: 'Network List',
    },
  },
]
