export default [
  {
    path: '/targets',
    name: 'targets',
    component: () => import('@/views/targets/Targets.vue'),
    meta: {
      resource: 'Target',
      action: 'Read',
      pageTitle: 'Targets',
      breadcrumb: [
        {
          text: 'Targets',
          active: true,
        },
      ],
    },
  },
  {
    path: '/targets/target/scans/:uid/',
    name: 'target-scans',
    props: true,
    component: () => import('@/views/targets/viewTarget/ScansTarget.vue'),
    meta: {
      resource: 'Target',
      action: 'Read',
      pageTitle: 'Vulnerability scans',
      breadcrumb: [
        {
          text: 'Targets',
          active: false,
          to: { name: 'targets' },
        },
        {
          text: 'Vulnerability Scans',
          active: true,
        },
      ],
    },
  },
  {
    path: '/targets/target/:uid',
    name: 'target-view',
    props: true,
    component: () => import('@/views/targets/viewTarget/ViewTarget.vue'),
    meta: {
      resource: 'Target',
      action: 'Read',
      pageTitle: 'Target',
      breadcrumb: [
        {
          text: 'Targets',
          active: false,
          to: { name: 'targets' },
        },
        {
          text: 'Target Details',
          active: true,
        },
      ],
    },
  },
]
