export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/Users.vue'),
    meta: {
      resource: 'User',
      action: 'Read',
      pageTitle: 'Users List',
    },
  },
  {
    path: '/users/user/:uid',
    name: 'user-view',
    props: true,
    component: () => import('@/views/users/ViewUser.vue'),
    meta: {
      resource: 'User',
      action: 'Read',
      pageTitle: 'User',
    },
  },
]
