export default [
  {
    path: '/issues',
    name: 'issues',
    component: () => import('@/views/issues/Issues.vue'),
    meta: {
      resource: 'Issue',
      action: 'Read',
      pageTitle: 'Issues',
      breadcrumb: [
        {
          text: 'Issues',
          active: true,
        },
      ],
    },
  },
  {
    path: '/issues/product/:targetUid/:uid',
    name: 'issues-product',
    props: true,
    component: () => import('@/views/products/Issues.vue'),
    meta: {
      resource: 'Issue',
      action: 'Read',
      pageTitle: 'Product Issues',
      breadcrumb: [
        {
          text: 'Product Issues',
          active: true,
        },
      ],
    },
  },
  {
    path: '/issues/target/:uid',
    name: 'issues-target',
    props: true,
    component: () => import('@/views/targets/viewTarget/sections/Issues.vue'),
    meta: {
      resource: 'Issue',
      action: 'Read',
      pageTitle: 'Target Issues',
      breadcrumb: [
        {
          text: 'Target Issues',
          active: true,
        },
      ],
    },
  },
  {
    path: '/issues/:uid',
    name: 'issue-view',
    props: true,
    component: () => import('@/views/issues/ViewIssue.vue'),
    meta: {
      resource: 'Issue',
      action: 'Read',
      pageTitle: 'Issue',
      breadcrumb: [
        {
          text: 'Issues',
          active: false,
          to: { name: 'issues' },
        },
        {
          text: 'Issue Details',
          active: true,
        },
      ],
    },
  },
]
