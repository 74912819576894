export default [
  {
    path: '/notification-groups',
    name: 'notification-groups',
    component: () => import('@/views/notifications/Groups.vue'),
    meta: {
      resource: 'NotificationGroup',
      action: 'Read',
      pageTitle: 'Notification Groups List',
    },
  },
]
